<template>
  <div>
    <div class="row mt-5">
      <div class="col-6"></div>
      <div class="col-6">
        <div class="text-end mt-4" style="margin-right: 19px">
          Visualizzati
          <b v-if="record > 0">
            {{ rowsToSkip + fetchRows - (fetchRows - 1) }}
            -
            {{
              rowsToSkip + fetchRows > record ? record : rowsToSkip + fetchRows
            }}
          </b>
          <b v-else> 0 </b>
          elementi di <b>{{ record }}</b>
        </div>
      </div>
    </div>
    <br />
    <div class="mx-auto my-auto text-center" v-if="!loaded">
      <div class="spinner-border text-gray-600" role="status"></div>
      <span class="text-gray-600 ps-3 fs-2">Loading</span>
    </div>
    <div
      v-else-if="data.length == 0 && loaded && (status == 200 || status == 204)"
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Nessun risultato.
      </span>
      <span
        type="button"
        data-bs-toggle=""
        data-bs-target=""
        aria-expanded="false"
        aria-controls=""
        class="text-gray-600 pt-4"
        @click.prevent="$emit('resetFilters')"
      >
        <i class="bi bi-arrow-clockwise fs-2 text-gray-800"> </i
      ></span>
    </div>
    <div
      v-else-if="status != 200 && status != 204"
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Si è verificato un errore. Riprovare più tardi.
      </span>
      <span
        type="button"
        data-bs-toggle=""
        data-bs-target=""
        aria-expanded="false"
        aria-controls=""
        class="text-gray-600 pt-4"
      >
        <router-link v-if="isEnabled('fnLinkBachecaCompleta')" to="/bacheca">
          <i class="bi bi-house fs-2 text-gray-800"> </i
        ></router-link>
        <router-link v-else to="/bacheca-public">
          <i class="bi bi-house fs-2 text-gray-800"> </i></router-link
      ></span>
    </div>
    <div v-else>
      <Datatable
        :table-header="tableHeader"
        :table-data="data"
        :total="record"
        :current-page="currentPage"
        :rows-per-page="fetchRows"
        :sortLabel="sortColumn"
        :order="sortOrder"
        @sort="setSortOrderColumn"
        @items-per-page-change="setFetchRows"
        @current-change="setCurrentPage"
      >
        <template v-slot:cell-persona="{ row: data }"
          ><div class="tab-long">
            {{ data.cognome }}, {{ data.nome }} - {{ data.data_nascita }}
          </div></template
        >
        <template v-slot:cell-username="{ row: data }">{{
          data.username
        }}</template>
        <template v-slot:cell-mail="{ row: data }">
          <div class="tab-long">{{ data.mail }}</div></template
        >
        <template v-slot:cell-data_creazione="{ row: data }"
          ><div class="text-center">
            {{ data.data_creazione }}
          </div>
        </template>
        <template v-slot:cell-data_ultimo_login="{ row: data }"
          ><div class="text-center">{{ data.data_ultimo_login }}</div></template
        >
        <template v-slot:cell-unlocked="{ row: data }"
          ><div class="text-center">
            <i
              v-if="!data.unlocked"
              class="bi bi-lock fs-4"
              :class="data.User_uuid ? 'text-danger' : 'text-grey-100'"
            ></i>
            <i
              v-if="data.unlocked"
              class="bi bi-unlock fs-4"
              :class="data.User_uuid ? 'text-success' : 'text-grey-100'"
            ></i></div
        ></template>
        <template v-slot:cell-options="{ row: data }">
          <div v-if="!data.User_uuid">
            <button
              class="btn btn-sm p-1"
              data-bs-toggle="modal"
              data-bs-target="#modal_add_utenza_abilitata"
              @click="selectedUtente = data"
            >
              <i class="bi bi-person-plus fs-4 text-dark"></i>
            </button>
          </div>
          <div v-if="data.User_uuid">
            <button
              class="btn btn-sm dropdown p-1"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i class="bi bi-three-dots-vertical text-dark fs-4"></i>
            </button>
            <ul class="dropdown-menu">
              <a
                href="#"
                v-if="!data.unlocked"
                @click="$emit('sbloccaUtente', data.User_uuid)"
              >
                <li role="presentation" class="navi-item">
                  <div class="b-dropdown-text text-start py-2 px-4 pe-3">
                    <div class="navi-link">
                      <i class="bi bi-unlock text-dark fs-3"></i>
                      <span class="text-dark ps-1 fs-7"> Sblocca utente </span>
                    </div>
                  </div>
                </li></a
              >
              <a
                href="#"
                v-if="data.unlocked"
                @click="$emit('bloccaUtente', data.User_uuid)"
              >
                <li role="presentation" class="navi-item">
                  <div class="b-dropdown-text text-start py-2 px-4 pe-3">
                    <div class="navi-link">
                      <i class="bi bi-lock text-dark fs-3"></i>
                      <span class="text-dark ps-1 fs-7"> Blocca utente </span>
                    </div>
                  </div>
                </li></a
              >
              <a
                href="#"
                v-if="data.unlocked"
                @click="$emit('resetPsw', data.User_uuid)"
              >
                <li role="presentation" class="navi-item">
                  <div class="b-dropdown-text text-start py-2 px-4 pe-3">
                    <div class="navi-link">
                      <i class="bi bi-key text-dark fs-3"></i>
                      <span class="text-dark ps-1 fs-7"> Reset password </span>
                    </div>
                  </div>
                </li></a
              >
            </ul>
          </div>
        </template>
      </Datatable>
    </div>
    <AddUtenzaAbilitata
      :utente="selectedUtente"
      @refreshList="$emit('getUtentiList')"
    />
  </div>
</template>

<script>
import Datatable from "../../../kt-datatable/KTDatatable.vue";
import { ref, computed } from "vue";
import { useStore } from "vuex";
import isEnabled from "@/composables/isEnabled.js";
import AddUtenzaAbilitata from "./AddUtenzaAbilitata.vue";

export default {
  name: "TableUsersPersone",
  components: { Datatable, AddUtenzaAbilitata },
  emits: [
    "getUtentiList",
    "resetFilters",
    "bloccaUtente",
    "sbloccaUtente",
    "resetPsw",
  ],
  props: {},

  setup(props, { emit }) {
    const tableHeader = ref([
      {
        name: "Persona",
        key: "persona",
      },
      {
        name: "Username",
        key: "username",
      },
      {
        name: "E-mail",
        key: "mail",
      },
      {
        name: "Data creazione",
        key: "data_creazione",
        align: "center",
      },
      {
        name: "Ultimo accesso",
        key: "data_ultimo_login",
        align: "center",
      },
      {
        name: "Stato",
        key: "unlocked",
        align: "center",
        sortable: false,
      },
      {
        key: "options",
        sortable: false,
      },
    ]);
    const store = useStore();

    const setFetchRows = (e) => {
      store.commit("setFetchRowsUtentiPersone", e);
      emit("getUtentiList");
    };
    const setCurrentPage = (page) => {
      store.commit("setCurrentPageUtentiPersone", page);
      emit("getUtentiList");
    };
    const setSortOrderColumn = ({ columnName, order }) => {
      store.commit("setSortColumnUtentiPersone", columnName);
      store.commit("setSortOrderUtentiPersone", order);
      emit("getUtentiList");
    };

    const selectedUtente = ref(null);

    return {
      tableHeader,
      setFetchRows,
      isEnabled,
      setCurrentPage,
      setSortOrderColumn,
      data: computed(() =>
        store.getters.getStateFromName("resultsutenti_persone_list")
      ),
      loaded: computed(() =>
        store.getters.getStateFromName("loadedutenti_persone_list")
      ),
      record: computed(() =>
        store.getters.getStateFromName("recordutenti_persone_list")
      ),
      status: computed(() =>
        store.getters.getStateFromName("statusutenti_persone_list")
      ),
      currentPage: computed(() => store.getters.currentPageUtentiPersone),
      rowsToSkip: computed(() => store.getters.rowsToSkipUtentiPersone),
      fetchRows: computed(() => store.getters.fetchRowsUtentiPersone),
      sortColumn: computed(() => store.getters.sortColumnUtentiPersone),
      sortOrder: computed(() => store.getters.sortOrderUtentiPersone),
      selectedUtente,
    };
  },
};
</script>

<style scoped></style>
